<template>
  <b-modal
    id="modal-modify-contact-info"
    :title="$t('reservation.contact')"
    title-class="text-airline"
    centered
    footer-class="d-flex justify-content-center"
    size="lg"
    no-close-on-backdrop
  >
    <template #modal-footer="{close}">
      <b-button
        size="md"
        variant="outline-secondary"
        class="center rounded-pill"
        @click="close()"
      >
        {{ $t('reservation.back') }}
      </b-button>
    </template>

    <div class="font-weight-bolder mb-50">
      {{ $t('reservation.agencyInfo') }}
    </div>

    <b-card-group deck>
      <!-- SECTION - phone -->
      <b-card
        class="border"
        header-bg-variant="light-info"
        header-class="py-50 font-weight-bold"
        body-class="py-1 px-50"
      >
        <template #header>
          <span>
            {{ $t('reservation.phoneNumber') }}
          </span>
        </template>

        <!-- NOTE - QH no edit phone -->
        <b-row
          v-if="['QH', 'AK', 'F1', 'TH'].includes(dataReservation.source)"
          class="mx-0"
        >
          <b-col
            v-for="(phone, index) in contactDataToAdd.phone"
            :key="index"
          >
            <b-form-group
              class="font-weight-bold"
            >
              <b-form-input
                v-model="contactDataToAdd.phone[index].address"
                :disabled="true"
              />
            </b-form-group>
            <i class="font-small-4 text-danger">*{{ $t('reservation.cannotChangePhone') }}</i>
          </b-col>
        </b-row>

        <div v-else>
          <b-row class="mx-0">
            <b-col
              v-for="(phone, index) in contactDataToAdd.phone"
              :key="index"
              cols="12"
              class="my-25 px-0"
            >
              <div
                v-if="!isEditPhone"
                class="d-flex"
              >
                <!-- <b-form-group
                  class="font-weight-bold"
                >
                  <v-select
                    :options="['+84', '+1', '+43']"
                    placeholder="Select"
                  />
                </b-form-group> -->
                <b-form-input
                  :id="`contact-phone-input-${index}`"
                  v-model="contactDataToAdd.phone[index].address"
                  :disabled="contactDataToAdd.phone[index] !== ''"
                />

                <!--
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-success ml-25"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="XIcon" />
                </b-button> -->

                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  dropright
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <!-- <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body ml-25"
                    /> -->
                    <b-button
                      v-if="canEdit"
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-info ml-25"
                      class="btn-icon rounded"
                    >
                      Sửa
                    </b-button>
                  </template>

                  <b-dropdown-item
                    @click="toggleEditPhone"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :disabled="source !== 'VN' || contactDataToAdd.phone.length < 2"
                    @click="removePhoneHandle(contactDataToAdd.phone[index])"
                  >
                    <feather-icon
                      variant="danger"
                      icon="TrashIcon"
                    />
                    <span class="align-middle ml-50">{{ $t('delete') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>

              <div
                v-else
                class="d-flex"
              >
                <validation-provider
                  #default="validationContext"
                  name="Phone"
                  rules="required|phoneNumber"
                  class="w-100"
                >
                  <b-form-input
                    :id="`contact-phone-input-${index}`"
                    v-model="blankDataPhone[index]"
                    :state="getValidationState(validationContext) === false ? false : null"
                    lazy-formatter
                    :formatter="trimInput"
                    :debounce="300"
                    :placeholder="contactDataToAdd.phone[index].address"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
                <!-- TODO - disabled -->
                <!-- :disabled="blankDataPhone[index] === ''" -->
                <b-button
                  :id="`contact-phone-done-${index}`"
                  variant="flat-success"
                  class="ml-50 px-50 text-nowrap"
                  @click="handleEditPhone(contactDataToAdd.phone[index], blankDataPhone[index], index)"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>

                <b-button
                  :id="`contact-phone-cancel-${index}`"
                  variant="flat-danger"
                  class="ml-50 px-50 text-nowrap"
                  @click="toggleEditPhone"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-button
            v-if="canEdit"
            v-b-modal.modal-reservation-modify-add-phone
            variant="flat-info"
            class="ml-50 m-50 text-nowrap"
            :disabled="source !== 'VN'"
          >
            <feather-icon icon="PlusIcon" />
            {{ $t('reservation.addMorePhone') }}
          </b-button>
        </div>
      </b-card>
      <!-- !SECTION -->

      <!-- SECTION - Email -->
      <b-card
        class="border"
        header-bg-variant="light-info"
        header-class="py-50 font-weight-bold"
        body-class="py-1 px-50"
      >
        <template #header>
          <span>
            Email
          </span>
        </template>

        <!-- NOTE - QH no edit email -->
        <b-row
          v-if="['QH', 'AK', 'F1', 'TH'].includes(dataReservation.source)"
          class="mx-0"
        >
          <b-col
            v-for="(email, index) of contactDataToAdd.email"
            :key="index"
          >
            <b-form-group
              class="font-weight-bold"
            >
              <b-form-input
                v-model="contactDataToAdd.email[index].address"
                :disabled="true"
                class="text-truncate"
              />
            </b-form-group>
            <i class="font-small-4 text-danger">*{{ $t('reservation.cannotChangeEmail') }}</i>
          </b-col>
        </b-row>

        <div v-else>
          <b-row class="mx-0">
            <b-col
              v-for="(email, index) of contactDataToAdd.email"
              :key="index"
              cols="12"
              class="p-0 my-25"
            >
              <div
                v-if="!isEditEmail"
                class="d-flex"
              >
                <!-- v-remove-non-numeric-chars -->
                <!-- @input="val=> contactDataToAdd.email[index] = val.toString().toUpperCase()" -->
                <!-- lazy-formatter
                :formatter="trimInput"
                :debounce="300"
                :placeholder="$t('customer.placeholderPhoneNumber')" -->
                <b-form-input
                  :id="`contact-email-${index}`"
                  v-model="contactDataToAdd.email[index].address"
                  :disabled="contactDataToAdd.email[index] !== ''"
                  class="text-truncate"
                />

                <b-dropdown
                  :id="`contact-email-${index}`"
                  variant="link"
                  toggle-class="p-0"
                  dropright
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <!-- <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body ml-25"
                    /> -->

                    <b-button
                      v-if="canEdit"
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-info ml-25"
                      class="btn-icon rounded"
                    >
                      <!-- <feather-icon icon="Edit3Icon" /> -->
                      Sửa
                    </b-button>
                  </template>

                  <!-- Dropdown: Edit -->
                  <b-dropdown-item
                    @click="toggleEditEmail"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                  </b-dropdown-item>

                  <!-- Dropdown: Delete -->
                  <b-dropdown-item
                    :disabled="source !== 'VN' || contactDataToAdd.email.length < 2"
                    @click="removeEmailHandle(contactDataToAdd.email[index])"
                  >
                    <feather-icon
                      variant="danger"
                      icon="TrashIcon"
                    />
                    <span class="align-middle ml-50">{{ $t('delete') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>

              <div
                v-else
                class="d-flex"
              >
                <validation-provider
                  #default="validationContext"
                  name="Email"
                  rules="required|max:50|email"
                  class="w-100"
                >
                  <b-form-input
                    :id="`contact-email-${index}`"
                    v-model="blankDataEmail[index]"
                    lazy-formatter
                    :formatter="trimInput"
                    :debounce="300"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :placeholder="contactDataToAdd.email[index].address"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
                <!-- :disabled="blankDataEmail[index] === '' || emailToAdd.toUpperCase() === contactDataToAdd.email[index].split('|')[1]" -->
                <b-button
                  :id="`contact-email-done-${index}`"
                  variant="flat-success"
                  class="ml-50 px-50 text-nowrap"
                  @click="handleEditEmail(contactDataToAdd.email[index], blankDataEmail[index], index)"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>

                <b-button
                  :id="`contact-email-cancel-${index}`"
                  variant="flat-danger"
                  class="ml-50 px-50 text-nowrap"
                  @click="toggleEditEmail"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-button
            v-if="canEdit"
            v-b-modal.modal-reservation-modify-add-email
            variant="flat-info"
            class="ml-50 m-50 text-nowrap"
            :disabled="source !== 'VN'"
          >
            <feather-icon icon="PlusIcon" />
            {{ $t('reservation.addMoreEmail') }}
          </b-button>
        </div>
      </b-card>
      <!-- !SECTION -->

      <!-- ANCHOR Modal Add phone -->
      <b-modal
        id="modal-reservation-modify-add-phone"
        title="Thêm số điện thoại"
        centered
        @show="resetModalAddPhone"
        @hidden="resetModalAddPhone"
      >
        <b-form
          ref="form-add-phone"
          @submit.stop.prevent="handleSubmitModalAddPhone"
        >
          <validation-provider
            #default="validationContext"
            name="Phone"
            rules="required|phoneNumber"
            class="w-100"
          >
            <b-form-group
              label="Số điện thoại"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="phoneToAdd"
                :state="getValidationState(validationContext) === false ? false : null"
                lazy-formatter
                :formatter="trimInput"
                :debounce="300"
                placeholder="Nhập vào số điện thoại"
              />
              <!-- maxlength="10" -->
            </b-form-group>
            <b-form-invalid-feedback
              :state="getValidationState(validationContext) === false ? false : null"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form>

        <template #modal-footer="{close}">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            size="md"
            variant="outline-secondary"
            class="center rounded-pill width-100"
            @click="close()"
          >
            {{ $t('reservation.close') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient mt-lg-25  border-0"
            pill
            @click="handleOkModalAddPhone"
          >
            <span class="align-middle">{{ $t('reservation.confirm') }}</span>
          </b-button>
        </template>
      </b-modal>

      <!-- ANCHOR Modal Add email -->
      <b-modal
        id="modal-reservation-modify-add-email"
        title="Thêm email"
        centered
        @show="resetModalAddEmail"
        @hidden="resetModalAddEmail"
      >
        <b-form
          ref="form-add-phone"
          @submit.stop.prevent="handleSubmitModalAddPhone"
        >
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|max:50|email"
            class="w-100"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="emailToAdd"
                :state="getValidationState(validationContext) === false ? false : null"
                lazy-formatter
                :formatter="trimInput"
                :debounce="300"
                placeholder="Nhập vào email"
              />
            </b-form-group>
            <b-form-invalid-feedback
              :state="getValidationState(validationContext) === false ? false : null"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form>

        <template #modal-footer="{close}">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            size="md"
            variant="outline-secondary"
            class="center rounded-pill width-100"
            @click="close()"
          >
            {{ $t('reservation.close') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient mt-lg-25  border-0"
            pill
            @click="handleOkModalAddEmail"
          >
            <span class="align-middle">{{ $t('reservation.confirm') }}</span>
          </b-button>
        </template>
      </b-modal>
    </b-card-group>
  </b-modal>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BCard,
  BCardGroup,
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  BForm,
  VBModal,
  BFormInvalidFeedback,

} from 'bootstrap-vue'
import {
  ref, computed, watch,
} from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { useToast } from 'vue-toastification/composition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardGroup,
    BFormInput,
    BFormGroup,
    BCol,
    BRow,
    BForm,
    BFormInvalidFeedback,

    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    dataReservation: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const toast = useToast()
    const { modifyPaxInfo, NDC_1A_BOOKING } = useReservationHandle()

    const contactDataToAdd = ref(cloneDeep(props.dataReservation.contact.paxInfo))
    const source = computed(() => props.dataReservation?.source || '')

    watch(() => props.dataReservation.contact.paxInfo, () => {
      contactDataToAdd.value = cloneDeep(props.dataReservation.contact.paxInfo)
    }, { deep: true })

    const resetContactDataToEdit = () => {
      contactDataToAdd.value = cloneDeep(props.dataReservation.contact.paxInfo)
    }

    const { refFormObserver, getValidationState } = formValidation(
      resetContactDataToEdit,
    )

    // phone
    const isEditPhone = ref(false)
    const blankDataPhone = computed(() => contactDataToAdd.value.phone.map(() => ('')))
    const phoneToAdd = ref(null)

    function resetModalAddPhone() {
      phoneToAdd.value = null
    }

    function handleOkModalAddPhone(bvModalEvt) {
      bvModalEvt.preventDefault()

      // Trigger submit handler
      this.handleSubmitModalAddPhone()
    }

    function handleSubmitModalAddPhone() {
      if (!phoneToAdd.value) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Không được để trống',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        this.$bvModal.show('modal-api-loading')
        modifyPaxInfo(props.dataReservation, {
          phoneUpdates: [
            {
              phone: String(phoneToAdd.value),
              operation: 'ADD',
            },
          ],
        })
          .then((() => {
            resetModalAddPhone()
            this.$bvModal.hide('modal-reservation-modify-add-phone')
          }))
          .catch((() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Thêm số điện thoại thất bại',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }))
          .finally(() => this.$bvModal.hide('modal-api-loading'))
      }
    }

    function toggleEditPhone() {
      isEditPhone.value = !isEditPhone.value
    }

    function removePhoneHandle(phoneToDel) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('Xoá số điện thoại đã chọn?') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: 'Xác nhận',
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            const phoneId = phoneToDel.id
            if (phoneId) {
              this.$bvModal.show('modal-api-loading')
              modifyPaxInfo(props.dataReservation, {
                phoneUpdates: [
                  {
                    operation: 'DELETE',
                    id: String(phoneId),
                  },
                ],
              }).finally(() => this.$bvModal.hide('modal-api-loading'))
            } else {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Xoá số điện thoại không thành công!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        })
    }

    function handleEditPhone(phoneToDel, phoneToAdd, index) {
      let payload

      if (!phoneToAdd) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Không được để trống',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return
      }
      if (source.value === 'VN') {
        payload = {
          phoneUpdates: [
            {
              operation: 'DELETE',
              id: String(phoneToDel.id),
            },
            {
              phone: String(phoneToAdd),
              operation: 'ADD',
            },
          ],
        }
      }

      if (['VJ', 'VU', '1A'].includes(source.value)) {
        payload = {
          phoneUpdates: [
            {
              phone: String(phoneToAdd),
              operation: 'UPDATE',
            },
          ],
        }
      }

      this.$bvModal.show('modal-api-loading')
      modifyPaxInfo(props.dataReservation, payload)
        .then(() => {
          blankDataPhone.value[index] = ''
          toggleEditPhone()
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => this.$bvModal.hide('modal-api-loading'))
    }

    // email
    const blankDataEmail = computed(() => contactDataToAdd.value.email.map(() => ('')))
    const isEditEmail = ref(false)
    const emailToAdd = ref(null)

    function resetModalAddEmail() {
      emailToAdd.value = null
    }

    function handleOkModalAddEmail(bvModalEvt) {
      bvModalEvt.preventDefault()

      // Trigger submit handler
      this.handleSubmitModalAddEmail()
    }

    function handleSubmitModalAddEmail() {
      if (!emailToAdd.value) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Không được để trống',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        this.$bvModal.show('modal-api-loading')
        modifyPaxInfo(props.dataReservation, {
          emailUpdates: [
            {
              address: String(emailToAdd.value),
              operation: 'ADD',
            },
          ],
        })
          .then((() => {
            resetModalAddEmail()
            this.$bvModal.hide('modal-reservation-modify-add-email')
          }))
          .catch((() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Thêm email thất bại',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }))
          .finally(() => this.$bvModal.hide('modal-api-loading'))
      }
    }

    function removeEmailHandle(emailToDel) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('Xoá email đã chọn?') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: 'Xác nhận',
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            const emailId = emailToDel.id
            if (emailId) {
              this.$bvModal.show('modal-api-loading')
              modifyPaxInfo(props.dataReservation, {
                emailUpdates: [
                  {
                    operation: 'DELETE',
                    id: String(emailId),
                  },
                ],
              }).finally(() => this.$bvModal.hide('modal-api-loading'))
            } else {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Xoá email không thành công!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        })
    }

    function toggleEditEmail() {
      isEditEmail.value = !isEditEmail.value
    }

    function handleEditEmail(emailToDel, emailToAdd, index) {
      let payload

      if (!emailToAdd) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Không được để trống',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return
      }

      if (source.value === 'VN') {
        payload = {
          emailUpdates: [
            {
              operation: 'DELETE',
              id: String(emailToDel.id),
            },
            {
              address: String(emailToAdd),
              operation: 'ADD',
            },
          ],
        }
      }

      if (['VJ', 'VU', '1A'].includes(source.value)) {
        payload = {
          emailUpdates: [
            {
              address: String(emailToAdd),
              operation: 'UPDATE',
            },
          ],
        }
      }

      this.$bvModal.show('modal-api-loading')
      modifyPaxInfo(props.dataReservation, payload)
        .then(() => {
          blankDataEmail.value[index] = ''
          toggleEditEmail()
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => this.$bvModal.hide('modal-api-loading'))
    }

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const meData = computed(() => store.getters['userStore/getMeData'])
    const isADM = computed(() => meData.value?.type === 'ADM')

    const canEdit = computed(() => (isRoleF1.value || (isRoleF2.value && isADM.value))
                                && !['1A'].includes(props.dataReservation?.source)) // 1A-GDS cannot edit
    // || !(['1A'].includes(props.dataReservation?.source) && !NDC_1A_BOOKING.value)) // 1A-GDS can edit

    return {
      contactDataToAdd,
      trimInput,
      source,
      refFormObserver,
      getValidationState,

      phoneToAdd,
      isEditPhone,
      blankDataPhone,
      toggleEditPhone,
      handleEditPhone,
      removePhoneHandle,
      resetModalAddPhone,
      handleOkModalAddPhone,
      handleSubmitModalAddPhone,

      emailToAdd,
      isEditEmail,
      blankDataEmail,
      toggleEditEmail,
      handleEditEmail,
      removeEmailHandle,
      resetModalAddEmail,
      handleOkModalAddEmail,
      handleSubmitModalAddEmail,

      canEdit,
    }
  },
}
</script>
